<template>
  <div class="heizungsart formItem">
    <div>Ich heizen mit:</div>
    <div class="btns">
      <input
        type="radio"
        class="btn-check"
        name="options"
        id="option1"
        autocomplete="off"
        value="Oelheizung"
        v-model="heizung"
      />
      <label class="btn btn-primary" for="option1">Ölheizung</label>

      <input
        type="radio"
        class="btn-check"
        name="options"
        id="option2"
        autocomplete="off"
        value="Gasheizung"
        v-model="heizung"
      />
      <label class="btn btn-primary" for="option2">Gasheizung</label>
    </div>
  </div>

  <div class="formItem info" v-if="heizung == 'Oelheizung'">
    <label for="oelverbrauch">Öl Jahresverbrauch</label>
    <div class="input">
      <input
        type="text"
        id="oelverbrauch"
        name="oelverbrauch"
        v-model="oel"
        inputmode="numeric"
      />
      <div class="info tip">
        i
        <span class="tooltiptext"
          >Durschnitsverbrauchaus min. den letzten 3 Jahren</span
        >
      </div>
      <div class="einheit">L</div>
    </div>
  </div>

  <!-- 
        Nutzungsgrad der Heizung (NG)
        Der Nutzungsgrad von Heizungsanlagen (Jahresnutzungsgrad) gibt an, wieviel von der im Energieträger gespeicherten Energie auch tatsächlich in einer gesamten Heizperiode genutzt werden kann.

      80% - 90% Niedertemperaturheizung
      90 - 95% Brennwertheizung

      - Ölkessel 77 %
      - Gaskessel 77 %
      - Gasthermen 80 %
      - Gas-Brennwertkessel 89 bis 96 % (abhängig von der Vorlauftemperatur)
      - Fernwärme 88 %
      -->
  <div class="formItem info" v-if="heizung == 'Gasheizung'">
    <label for="gasverbrauch">Gas Jahresverbrauch in kWh</label>
    <div class="input">
      <input
        type="text"
        id="gasverbrauch"
        name="gasverbrauch"
        v-model="gas"
        inputmode="numeric"
      />
      <div class="info tip">
        i
        <span class="tooltiptext">aus der Rechnung des Gasversorgers</span>
      </div>
      <div class="einheit">kWh</div>
    </div>
  </div>

  <div class="formItem">
    <label for="nutzungsgrad" class="form-label">
      Nutzungsgrad der Heizung
    </label>
    <div class="row">
      <div class="col-md-auto">
        <input
          type="range"
          class="form-range"
          name="nutzungsgrad"
          id="nutzungsgrad"
          min="75"
          max="95"
          step="1"
          v-model="nutzungsgrad"
        />
      </div>
      <div class="col">{{ nutzungsgrad }} %</div>
    </div>
  </div>

  <div class="formItem">
    <label for="personen">Wieviele Personen leben im Haushalt</label>
    <div class="input">
      <input
        type="text"
        id="personen"
        name="personen"
        v-model="personen"
        inputmode="numeric"
      />
      <div class="einheit">Personen</div>
    </div>
  </div>

  <div class="formItem">
    <label for="warmwasserproperson" class="form-label"
      >Energieverbrauch für Warmwasser / Person</label
    >
    <div class="row">
      <div class="col-md-auto">
        <input
          type="range"
          class="form-range"
          name="warmwasserproperson"
          id="warmwasserproperson"
          min="800"
          max="1400"
          step="50"
          v-model="warmwasserProPerson"
        />
      </div>
      <div class="col">{{ warmwasserProPerson }} kWh im Jahr</div>
    </div>
  </div>

  <!-- Warmwasserheizleistung (WW) pro Person Jahresverbrauch zwischen 800 - 1400 kWh / Jahr  -->
  <!-- 
        WWgesamt = WWproPers * Personen  
        HeizleistungGesamt
        jahresverbrauchWaerme = (LiterÖl L * 0,98 kWh/L * NG) - WWgesamt
      -->
  <div class="formItem">
    <label for="gesamteHeizleistung" class="form-label">
      Gesamte jährliche Heizleistung + Trinkwasserer
    </label>
    <div class="input">
      <input
        type="text"
        id="gesamteHeizleistung"
        name="gesamteHeizleistung"
        readonly
        :value="formatNumberWithCommas(gesamteHeizleistung(), 0)"
      />
      <div class="einheit">kWh</div>
    </div>
  </div>

  <div class="formItem">
    <label for="gesamteHeizleistung" class="form-label">
      jährliche Heizleistung Trinkwassererwärmung
    </label>
    <div class="input">
      <input
        type="text"
        id="warmwasser"
        name="warmwasser"
        readonly
        :value="formatNumberWithCommas(warmwasser(), 0)"
      />
      <div class="einheit">kWh</div>
    </div>
  </div>

  <div class="formItem">
    <label for="gesamteHeizleistung" class="form-label">
      Benötigte jährliche Heizleistung Raumluft
    </label>
    <div class="input">
      <input
        type="text"
        id="gesamteHeizleistungOhneWW"
        name="gesamteHeizleistungOhneWW"
        readonly
        :value="formatNumberWithCommas(gesamteHeizleistungOhneWW(), 0)"
      />
      <div class="einheit">kWh</div>
    </div>
  </div>

  <div class="formItem">
    <label for="plz" class="form-label"> Ihre Postleitzahl </label>
    <div class="input">
      <input
        type="text"
        id="plz"
        name="plz"
        v-model="plz"
        inputmode="numeric"
        placeholder="Ihre Postleitzahl"
        @change="checkPLZ()"
      />
      <div class="einheit">&nbsp;</div>
    </div>
  </div>

  <div class="formItem info">
    <label for="jahresmitteltemperatur" class="form-label"
      >Jahresmitteltemperatur</label
    >
    <div class="input">
      <input
        type="text"
        id="jahresmitteltemperatur"
        name="jahresmitteltemperatur"
        readonly
        :value="jahresmitteltemperatur"
      />
      <div class="info tip">
        i
        <span class="tooltiptext">Jahresmitteltemperatur am Standort</span>
      </div>
      <div class="einheit">°C</div>
    </div>
  </div>

  <div class="formItem info">
    <label for="normausentemperatur" class="form-label"
      >Normausentemperatur</label
    >
    <!-- 
  Die Normaußentemperatur ist die tiefste Temperatur einer Kälteperiode, welche sich 10 Mal innerhalb von 20 Jahren über einen Zeitraum von mindestens zwei aufeinanderfolgenden Tagen gehalten haben muss.
  https://www.waermepumpe.de/normen-technik/klimakarte/
  -->
    <div class="input">
      <input
        type="text"
        id="normausentemperatur"
        name="normausentemperatur"
        readonly
        :value="normausentemperatur"
      />
      <div class="info tip">
        i
        <span class="tooltiptext"
          >Die Normaußentemperatur ist die tiefste Temperatur einer
          Kälteperiode, welche sich 10 Mal innerhalb von 20 Jahren über einen
          Zeitraum von mindestens zwei aufeinanderfolgenden Tagen gehalten haben
          muss.</span
        >
      </div>
      <div class="einheit">°C</div>
    </div>
  </div>

  <div class="formItem info">
    <label for="heizgrenztemperatur" class="form-label"
      >Heizgrenztemperatur in D 15°</label
    >
    <div class="input">
      <input
        type="text"
        id="heizgrenztemperatur"
        name="heizgrenztemperatur"
        v-model="heizgrenztemperatur"
      />
      <div class="info tip">
        i
        <span class="tooltiptext"
          >Aussentemperatur ab der Heizung benötigt wird<br />
          Altbau ca. 15°C</span
        >
      </div>
      <div class="einheit">°C</div>
    </div>
  </div>

  <div class="formItem info">
    <!-- Belastungsgrad = ( Heizgrenztemperatur - Jahresmitteltemperatur ) / ( Heizgrenztemperatur - Normausentemperatur )  -->
    <label class="form-label" for="belastungsgrad">Belastungsgrad</label>
    <div class="input">
      <input
        type="text"
        id="belastungsgrad"
        name="belastungsgrad"
        readonly
        :value="formatNumberWithCommas(belastungsgrad(), 3)"
      />
      <div class="info tip">
        i
        <span class="tooltiptext"
          >Faktor aus<br />
          (Heizgrenztemp. - Jahresmitteltemp.) / (Heizgrenztemp. -
          Normausentemp.)</span
        >
      </div>
      <div class="einheit">&nbsp;</div>
    </div>
  </div>

  <div class="formItem info">
    <!-- VollaststundenHeizung = StundenImJahr * Belastungsgrad  -->
    <label class="form-label" for="vollaststunden"
      >Vollaststunden Heizung</label
    >
    <div class="input">
      <input
        type="text"
        id="vollaststunden"
        name="vollaststunden"
        readonly
        :value="formatNumberWithCommas(vollaststunden(), 0)"
      />
      <div class="info tip">
        i
        <span class="tooltiptext"
          >Stunden im Jahr in denen eine Heizung benötigt wird.</span
        >
      </div>

      <div class="einheit">h</div>
    </div>
  </div>

  <div class="formItem info">
    <!-- VollaststundenHeizung / jahresverbrauchWaerme / qmBeheizteWohnfläche -->
    <label for="wohnflaeche" class="form-label">Wohnfläche</label>
    <div class="input">
      <input
        type="text"
        id="wohnflaeche"
        name="wohnflaeche"
        v-model="wohnflaeche"
        inputmode="numeric"
      />
      <div class="info tip">
        i
        <span class="tooltiptext">beheizte Wohnfläche</span>
      </div>
      <div class="einheit">m²</div>
    </div>
  </div>

  <div class="formItem info">
    <label class="form-label" for="heizleistungGebaeude">
      Benötigte Heizleistung
    </label>
    <div class="input">
      <input
        type="text"
        id="heizleistungGebaeude"
        name="heizleistungGebaeude"
        readonly
        :value="formatNumberWithCommas(heizleistungGebaeude(), 1)"
      />
      <div class="info tip">
        i
        <span class="tooltiptext">Für das Gebäude benötigte Heizleistung</span>
      </div>
      <div class="einheit">kW</div>
    </div>
  </div>

  <div class="formItem info">
    <label class="form-label" for="heizleistungProQm">
      benötigte Heizleistung pro qm
    </label>
    <div class="input">
      <input
        type="text"
        id="heizleistungProQm"
        name="heizleistungProQm"
        readonly
        :value="formatNumberWithCommas(heizleistungProQm(), 0)"
      />
      <div class="info tip">
        i
        <span class="tooltiptext"
          >max. Heizleistung pro qm zur Auslegung der Heizkörper</span
        >
      </div>
      <div class="einheit">W/m²</div>
    </div>
  </div>

  <div class="formItem info">
    <label class="form-label" for="heizleistungQm"> Heizleistung pro qm </label>
    <div class="input">
      <input
        type="text"
        id="heizleistungQm"
        name="heizleistungQm"
        readonly
        :value="formatNumberWithCommas(heizleistungQm(), 0)"
      />
      <div class="info tip">
        i
        <span class="tooltiptext">Durschnitsverbrauch pro m² pro Jahr</span>
      </div>
      <div class="einheit">kW/m²</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      heizung: "Oelheizung",
      oel: 0,
      gas: 0,
      energiegehaltOel: 9.8,
      nutzungsgrad: 80,
      warmwasserProPerson: 1100,
      personen: 2,

      plz: "",

      jahresmitteltemperatur: 7,
      normausentemperatur: -10,
      heizgrenztemperatur: 15,

      wohnflaeche: 100,

      name: "Vue",
    };
  },
  methods: {
    heizleistungQm() {
      return this.gesamteHeizleistungOhneWW() / this.wohnflaeche;
    },
    checkPLZ() {
      if (this.plz.length == 5) {
        const url = `https://heizung.chaba.de/klima_data.php?plz=${this.plz}`;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors", // You can include this option if you're making a cross-origin request
        })
          .then((response) => {
            // Check if the request was successful (status code 200-299)
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // Parse the JSON in the response
            return response.json();
          })
          .then((data) => {
            // Handle the data from the response
            console.log("Data:", data);
            this.jahresmitteltemperatur = data.jmt;
            this.normausentemperatur = data.nat;
          })
          .catch((error) => {
            // Handle errors
            console.error("Error:", error);
          });
      }
    },
    belastungsgrad() {
      const bg =
        (this.heizgrenztemperatur - this.jahresmitteltemperatur) /
        (this.heizgrenztemperatur - this.normausentemperatur);
      return bg;
    },
    gesamteHeizleistung() {
      let gh = 0;
      if (this.heizung == "Oelheizung") {
        gh = this.oel * this.energiegehaltOel * (this.nutzungsgrad / 100);
      }
      if (this.heizung == "Gasheizung") {
        gh = this.gas * (this.nutzungsgrad / 100);
      }
      return gh;
    },
    warmwasser() {
      const ww = this.warmwasserProPerson * this.personen;
      return ww;
    },
    gesamteHeizleistungOhneWW() {
      const gho = this.gesamteHeizleistung() - this.warmwasser();
      return gho;
    },
    vollaststunden() {
      const vs = 8760 * this.belastungsgrad();
      return vs;
    },
    heizleistungProQm() {
      const hpqm =
        (this.gesamteHeizleistungOhneWW() /
          this.vollaststunden() /
          this.wohnflaeche) *
        1000; // KWh in W
      return hpqm;
    },
    heizleistungGebaeude() {
      const hG = this.gesamteHeizleistungOhneWW() / this.vollaststunden();
      return hG;
    },
    formatNumberWithCommas(number, digits) {
      // Check if the inputs are valid
      if (
        typeof number !== "number" ||
        isNaN(number) ||
        typeof digits !== "number" ||
        isNaN(digits)
      ) {
        return "Invalid input";
      }
      // Convert number to fixed number of digits after the decimal point
      const formattedNumber = number.toFixed(digits);
      return formattedNumber.toString().replace(".", ",");
    },
  },
};
</script>
